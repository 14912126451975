<template>
  <div>
    <Chart :lang="lang" :cdata="cdata" :photovoltaicType="photovoltaicType"/>
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  props: {
    lang: {
        default: 'zh'
    },
    photovoltaicType: {
      default: 1
    },
    cdata: {},
  },
  data() {
    return {
      // cdata: {
      //   category: [
      //     "一月",
      //     "二月",
      //     "三月",
      //     "四月",
      //     "五月",
      //     "六月",
      //     "七月",
      //     "八月",
      //     "九月",
      //     "十月",
      //     "十一月",
      //     "十二月"
      //   ],
      //   lineData: [
      //     18092,
      //     20728,
      //     24045,
      //     28348,
      //     32808,
      //     36097,
      //     39867,
      //     44715,
      //     48444,
      //     50415,
      //     56061,
      //     62677,
      //   ],
      //   barData: [
      //     4600,
      //     5000,
      //     5500,
      //     6500,
      //     7500,
      //     8500,
      //     9900,
      //     12500,
      //     14000,
      //     21500,
      //     23200,
      //     24450,
      //     25250,
      //   ],
      //   rateData: []
      // }
    };
  },
  components: {
    Chart,
  },
  mounted() {
    // this.setData();
  },
  methods: {
    // 根据自己的业务情况修改
    setData() {
      for (let i = 0; i < this.cdata.barData.length - 1; i++) {
        let rate = this.cdata.barData[i] / this.cdata.lineData[i];
        this.cdata.rateData.push(rate.toFixed(2));
      }
    },
  }
};
</script>

<style lang="scss" scoped></style>