<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span v-if="lang == 'zh'" class="title-text">智慧储能照明大数据可视化平台</span>
              <span style="width: 700px;" v-if="lang == 'en'" class="title-text">Smart energy storage lighting big
                data</span>

              <dv-decoration-6 class="dv-dec-6" :reverse="true" :color="['#50e3c2', '#67a1e5']" />
            </div>
            <dv-decoration-8 class="dv-dec-8" :reverse="true" :color="decorationColor" />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>

        <!-- 第二行 -->
        <div style="width: 100%;display: flex;justify-content: flex-end;margin-top: -35px;padding-bottom: 5px;">
          <div style="font-size: 22px;" class="goto">
            <a style="color: #fff;" href="http://hzxk.xujingkj.com/admin" target="_blank">前往管理后台</a>
          </div>
        </div>

        <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width" style="width: 30%;">
            <div class="react-left ml-4 react-l-s" style="
                display: flex;
                justify-content: space-between;
                padding-right: 15px;
              ">
              <span class="react-left"></span>
              <span v-if="lang == 'zh'" class="text">数据分析1</span>
              <span v-if="lang == 'en'" class="text">Data Analysis 1</span>

              <!-- <span class="text">已稳定运行时长100天</span> -->
            </div>
            <!-- <div class="react-left ml-3">
                <span class="text">数据分析2</span>
              </div> -->
          </div>


          <div class="d-flex aside-width" style="width: 70%;">
            <div class="react-right bg-color-blue mr-3 cur" style="width: 245px;"
              :class="photovoltaicType == 1 ? 'photovoltaicTypeBg' : ''" @click="photovoltaicFun(1)">
              <span v-if="lang == 'zh'" class="text fw-b" style="width: 100%;height: 100%;color: #fff;">削锋填谷储能照明</span>
              <span v-if="lang == 'en'" class="text fw-b" style="width: 100%;height: 100%;color: #fff;">Energy storage lighting</span>
            </div>
            <div class="react-right bg-color-blue mr-3 cur" style="width: 245px;"
              :class="photovoltaicType == 2 ? 'photovoltaicTypeBg' : ''" @click="photovoltaicFun(2)">
              <span v-if="lang == 'zh'" class="text fw-b" style="width: 100%;height: 100%;color: #fff;">光伏储能照明</span>
              <span v-if="lang == 'en'" class="text fw-b" style="width: 100%;height: 100%;color: #fff;">photovoltaic</span>
            </div>
            <div class="react-right bg-color-blue mr-3" style="width: 245px;">
              <span v-if="lang == 'zh'" class="text fw-b" style="width: 100%;height: 100%;">
                <a style="color: #fff;display: block;width: 100%;height: 100%;" :href="jianKongUrl"
                  target="_blank">视频监控</a>
              </span>
              <span v-if="lang == 'en'" class="text fw-b" style="width: 100%;height: 100%;">
                <a style="color: #fff;display: block;width: 100%;height: 100%;" :href="jianKongUrl"
                  target="_blank">Video monitoring</a>
              </span>
            </div>
            <div class="react-right mr-4 react-l-s">
              <div style="display: inline-block;">
                <div style="display: flex;margin-right: 15px;">
                  <div class="text" style="display: flex;align-items: center;margin-right: 25px;" @click="lang = 'zh'">
                    <img src="../assets/cn.png" style="width: 32px;height: 16px;margin-right: 6px;" alt="">
                    <p>ch</p>
                  </div>

                  <div class="text" style="display: flex;align-items: center;" @click="lang = 'en'">
                    <img src="../assets/en.jpg" style="width: 32px;height: 16px;margin-right: 6px;" alt="">
                    <p>en</p>
                  </div>
                </div>
              </div>
              <span class="react-after"></span>
              <span class="text">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
            </div>
          </div>
        </div>

        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div>
              <dv-border-box-13>
                <center :lang="lang" :dianbiao="dianbiao" :dianliang="dianliang"  :photovoltaicType="photovoltaicType" :photovoltaicYery="photovoltaicYery"/>
              </dv-border-box-13>
            </div>

            <div  @click="goTOBms">
              <dv-border-box-12>
                <quantityL :lang="lang" :lightningNum="lightningNum" :lightningType="lightningType"/>
              </dv-border-box-12>
            </div>

            <div v-if="photovoltaicType == 1" @click="goToElectricity">
              <dv-border-box-12>
                <quantityR :lang="lang" :lightningNum="lightningNum" :batEcele_month="dianbiao"/>
              </dv-border-box-12>
            </div>
            <div v-else>
              <dv-border-box-12>
                <photovoltaic :lang="lang" :irradiation="homeData.irradiation" :sun_vin="homeData.sun_vin" :sun_cur="homeData.sun_cur"></photovoltaic>
              </dv-border-box-12>
            </div>

            <!-- 中间 -->
            <div>
              <dv-border-box-12>
                <centerLeft1 :lang="lang" :cdata="device_list" />
              </dv-border-box-12>
            </div>

            <!-- 中间 -->

            <div>
              <dv-border-box-13>
                <rightTop :lang="lang" :cofInfo="homeData.device_statistics" :a_bliPer="homeData.a_bliPer" />
                <!-- <centerRight1 /> -->
              </dv-border-box-13>
            </div>
          </div>

          <!-- 第四行数据 -->
          <div class="botto m-box" style="display: flex;">
            <div style="width: 28%" @click="goTo">
              <dv-border-box-12>
                <centerLeft2 :lang="lang" :cdata="homeData.area" :Region="homeData.areas_json" />
              </dv-border-box-12>
            </div>

            <div style="width: 72%">
              <dv-border-box-13>
                <bottomLeft :lang="lang" :photovoltaicType="photovoltaicType" :cdata="homeData.monthData" />
              </dv-border-box-13>
            </div>
           
          </div>

          <div v-if="lang == 'zh'" style="text-align: center">杭州萧科节能科技有限公司</div>
          <div v-if="lang == 'en'" style="text-align: center">Hangzhou Xiaoke Energy-saving Technology Co., Ltd.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from "../utils/index.js";
import centerLeft1 from "./centerLeft1";
import centerLeft2 from "./centerLeft2";
import rightTop from "./rightTop";
import quantityL from "./quantity1.vue";
import quantityR from "./quantity2.vue";
// import centerRight1 from './centerRight1'
// import centerRight2 from './centerRight2'
import center from "./center";
import bottomLeft from "./bottomLeft";
// import bottomRight from './bottomRight'
import photovoltaic from '@/components/photovoltaic'

import { indexDataAPI } from "@/api";

export default {
  mixins: [drawMixin],
  data() {
    return {
      // 1 为削锋填谷储能照明  2 为光伏储能照明
      photovoltaicType: 1,

      jianKongUrl: '',

      homeData: {},
      device_list: {},

      lang: 'zh',
      project_id: '',
      project_id_g: '',

      lightningNum: '90',
      lightningType: '2',

      dianbiao: 0,
      dianliang: 0,
      photovoltaicYery: '',

      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      weekdayEn: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      decorationColor: ["#568aea", "#000000"],
    };
  },
  components: {
    centerLeft1,
    centerLeft2,
    rightTop,
    quantityL,
    quantityR,
    // centerRight1,
    // centerRight2,
    center,
    bottomLeft,
    // bottomRight
    photovoltaic,
  },
  watch: {
    lang: {
      handler() {
        this.timeFn();
      }
    }
  },

  mounted() {
    // this.cancelLoading();
    if (localStorage.getItem('lang')) {
      this.lang = localStorage.getItem('lang')
    }

    let query = this.$route.query; // { key1: value1, key2: value2 }
    if(query.p) {
      this.project_id = query.p
    }
    if(query.g) {
      this.project_id_g = query.g
    }

    // this.jianKongUrl = 'https://xkdpcamera.xujingkj.com?p=' + this.project_id
    // this.jianKongUrl = 'https://xkdpcamera.xujingkj.com/jiankong/wpdemo.html'

    this.dataAPI()
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {

    async dataAPI() {
      let obj = {}
      if(this.project_id) {
        obj = { p: this.project_id }
      }
      if(this.project_id_g) {
        obj = { g: this.project_id_g }
      }
      const res = await indexDataAPI(obj);
      this.homeData = res

      this.device_list['xData'] = this.homeData.device_list
      this.device_list['seriesData'] = this.homeData.device_number_list

      // 低谷充电
      this.dianliang = this.homeData.totalEcele_month
      // 放电量
      this.dianbiao = this.homeData.batEcele_month
      // 光伏充电量(年
      this.photovoltaicYery = this.homeData.photovoltaic

      // 监控网址
      if (this.homeData.monitor_url) {
        this.jianKongUrl = this.homeData.monitor_url + '?p=' + this.project_id
      } else {
        this.jianKongUrl = 'https://xkdpcamera.xujingkj.com?p=' + this.project_id
      }

      this.lightningNum = this.homeData.device_info.battery
      if (this.homeData.device_info.dumpEnergy > 26.5 || this.homeData.device_info.dumpEnergy < 27) {
        this.lightningNum = 100
      } else if (this.homeData.device_info.dumpEnergy < 22) {
        this.lightningNum = 10
      }


      this.timeFn();
      this.device_info()

      this.loading = false;
      // console.log(this.homeData.device_number_list, "aaaaaaaaaaaaaaaaaaaaaaaaa");
    },
    timeFn() {
      localStorage.setItem('lang', this.lang);
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");

        if (this.lang == 'zh') {
          this.dateWeek = this.weekday[new Date().getDay()];
        }
        if (this.lang == 'en') {
          this.dateWeek = this.weekdayEn[new Date().getDay()];
        }

      }, 1000);
    },

    // 充电状态
    device_info() {


      let da = formatTime(new Date(), "HH: mm")
      // console.log(da);
      // da = '06:00'
      // if(this.isBetweenTime(da, [this.homeData.device_info.gled_a, this.homeData.device_info.gled_a_b])) {
      // console.log(this.isBetweenTime(da, [this.homeData.device_info.gled_a, this.homeData.device_info.gled_a_b]));
      // console.log(this.isBetweenTime(da, [this.homeData.device_info.gled_b_c, (formatTime(new Date(), "yyyy-MM-dd")) + this.homeData.device_info.gled_d]));
      // }


      // this.homeData.device_info.gled_a = "12:00"
      // this.homeData.device_info.gled_a_b = "12:20"
      // this.homeData.device_info.gled_b_c = "12:30"
      // this.homeData.device_info.gled_c_d = "12:40"
      // this.homeData.device_info.gled_d = "12:50"


      // 1 充电状态  2 放电状态  3 市电状态    其他 剩余电池电量 
      if (this.isBetweenTime(da, [this.homeData.device_info.gled_a, this.homeData.device_info.gled_a_b])) {
        this.lightningType = 2
      } else if (this.isBetweenTime(da, [this.homeData.device_info.gled_a_b, this.homeData.device_info.gled_b_c])) {
        this.lightningType = 3
      } else if (this.isBetweenTime(da, [this.homeData.device_info.gled_b_c, this.homeData.device_info.gled_d])) {
        this.lightningType = 1
      } else {
        this.lightningType = 1
      }



      // console.log(this.isBetweenTime(da, [this.homeData.device_info.gled_a, this.homeData.device_info.gled_a_b]));
      // console.log(this.isBetweenTime(da, [this.homeData.device_info.gled_a_b, this.homeData.device_info.gled_b_c]));
      // console.log(this.isBetweenTime(da, [this.homeData.device_info.gled_b_c, this.homeData.device_info.gled_d]));


    },

    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },





    // tab栏切换
    photovoltaicFun(num) {
      if (num == 1) {
        console.log('11111');
      } else if (num == 2) {
        console.log('22222', this.homeData.monthData);
        this.homeData.monthData.photovoltaicData = this.homeData.monthData.lineData
      }

      this.photovoltaicType = num

    },






    isBetweenTime(currentDate, timeQuantum) {
      let isBetween = true;
      let currentTime = new Date('2023-01-01 ' + currentDate);
      let startTime = new Date('2023-01-01 ' + timeQuantum[0]);
      let endTime = new Date('2023-01-01 ' + timeQuantum[1]);
      if (startTime.getTime() > endTime.getTime()) {
        if (currentTime.getHours() < '12') {
          currentTime = new Date('2023-01-02 ' + currentDate);
        }
        endTime = new Date('2023-01-02 ' + timeQuantum[1]);
      }

      let t1 = currentTime.getTime() - startTime.getTime();
      let t2 = currentTime.getTime() - endTime.getTime();
      if (t1 < 0 || t2 > 0) {
        isBetween = false;
      }

      return isBetween;
    },

    goTo() {
      let obj = {}
      if(this.project_id) {
        obj = { p: this.project_id }
      }
      if(this.project_id_g) {
        obj = { g: this.project_id_g }
      }
      this.$router.push({ path: '/map', query: obj });
    },
    goToElectricity() {
      let obj = {}
      if(this.project_id) {
        obj = { p: this.project_id }
      }
      if(this.project_id_g) {
        obj = { g: this.project_id_g }
      }
      this.$router.push({ path: '/Electricity', query: obj });
    },
// 前往BMS电池
    goTOBms() {
      let obj = {}
      if(this.project_id) {
        obj = { p: this.project_id }
      }
      if(this.project_id_g) {
        obj = { g: this.project_id_g }
      }
        this.$router.push({ path: '/BMS', query: obj });
    },


  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/index.scss";

.goto {
  border: 1px solid #2e6099;
  border-radius: 5px;
  padding: 8px;
}

.photovoltaicTypeBg {
  background-color: #43d2fe !important;
}

.cur {
  cursor: pointer;
}
</style>
