<template>
  <div id="centerLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-bar" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span v-if="lang == 'zh'" class="fs-xl text mx-2" style="font-size: 26px;">设备概况</span>
          <span v-if="lang == 'en'" class="fs-xl text mx-2" style="font-size: 26px;">Device</span>
          <dv-decoration-3 class="dv-dec-3" />
        </div>
      </div>
      <div class="d-flex jc-center" style="margin-top: -20px;">
        <CenterLeft1Chart :lang="lang" :cdata="cdata" />
      </div>
      <!-- 4个主要的数据 -->
      <div class="bottom-data">
        <div class="item-box" style="margin-top: 3px" v-for="(item, index) in numberData" :key="index">
          <div class="d-flex" style="display: flex; justify-content: center;">
            <span class="coin" style="font-size: 22px;">{{item.number.number[0]}}</span>
            <!-- <dv-digital-flop class="dv-digital-flop" :config="item.number" /> -->
          </div>
          <p class="text" style="text-align: center; margin-top: 10px;font-size: 22px;">
            {{ lang == 'zh' ? item.text : item.en }}
            <span v-if="lang == 'zh'" class="colorYellow">(台)</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CenterLeft1Chart from "@/components/echart/centerLeft/centerLeft1Chart";
export default {
  props: {
    lang: {
        default: 'zh'
    },
    cdata: {}
  },
  data() {
    return {
      numberData: [],
    };
  },
  components: {
    CenterLeft1Chart,
  },

  mounted() {
    this.changeTiming()
  },
  methods: {
    changeTiming() {


      // console.log('deviceNum', this.cdata.seriesData)

      for (var i = 0; i < this.cdata.seriesData.length; i++) {
        let item = this.cdata.seriesData[i]

        let obj = {
          number: {
            number: [10],
            toFixed: 1,
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 18,
              textAlign: "center",
            },
          },
          text: "开灯",
        }
        
        // console.log('item', item);
        obj.number.number[0] = item.value
        obj.text = item.name
        obj.en = item.en
        // console.log('obj', obj);
        this.numberData.push(obj)
      }

      // console.log('numberData', this.numberData)

      // setInterval(() => {
      //   this.changeNumber();
      // }, 3000);
    },
    changeNumber() {
      this.numberData.forEach((item, index) => {
        item.number.number[0] += ++index;
        item.number = { ...item.number };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$box-width: 340px;
$box-height: 410px;

#centerLeft1 {
  padding: 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;

  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }

  .text {
    color: #c3cbde;
  }

  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .bottom-data {
    margin-top: -20px;

    .item-box {
      &>div {
        padding-right: 5px;
      }

      font-size: 14px;
      float: right;
      position: relative;
      width: 50%;
      color: #d3d6dd;

      .dv-digital-flop {
        width: 120px;
        height: 30px;
      }

      // 金币
      .coin {
        position: relative;
        top: 6px;
        font-size: 20px;
        color: #3cdfc3;
      }

      .colorYellow {
        color: yellowgreen;
      }

      p {
        text-align: center;
      }
    }
  }
}
</style>
