<template>
  <div>
    <Chart :lang="lang" :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  props: {
    lang: {
        default: 'zh'
    },
    cdata: {}
  },
  data () {
    return {
      // cdata: {
      //   xData: ["总数", "开灯", "关灯", "在线", "离线", "故障"],
      //   seriesData: [
      //     { value: 20, name: "总数" },
      //     { value: 10, name: "开灯" },
      //     { value: 5, name: "关灯" },
      //     { value: 10, name: "在线" },
      //     { value: 3, name: "离线" },
      //     { value: 2, name: "故障" }
      //   ]
      // }
    }
  },
  components: {
    Chart,
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>