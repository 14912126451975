<template>
    <div id="centerRight1">
        <!-- <div class="bg-color-black">

        </div> -->
        <div style="display: flex;flex-wrap: wrap;justify-content: space-between;">

            <div class="item" v-for="(item, index) in cofInfo" :key="index">
                <p style="color: #247cff;font-size: 18px;">{{ lang == 'zh' ? item.title : item.en }}</p>
                <div style="margin-top: 8px;">
                    <span style="color: #3cdebf;font-size: 22px;">{{ lang == 'zh' ? item.content : item.contentEn }}</span>
                </div>
            </div>

        </div>

        <div class="right">
            <div>
                <span v-if="lang == 'zh'" style="font-size: 24px;">灯具亮度百分比</span>
                <span v-if="lang == 'en'" style="font-size: 24px;">Luminaire brightness</span>
            </div>
            <CenterChart :id="rate[0].id" :tips="rate[0].tips" :colorObj="rate[0].colorData" />
        </div>

    </div>
</template>

<script>

import CenterChart from '@/components/echart/center/centerChartRate'

export default {
    props: {
        lang: {
            default: 'zh'
        },
        cofInfo: [],
        a_bliPer: {
            default: ''
        },
    },
    data() {
        return {
            rate: [
                {
                    id: 'centerRate2',
                    tips: 40,
                    colorData: {
                        textStyle: '#67e0e3',
                        series: {
                            color: ['#faf3a378', 'transparent'],
                            dataColor: {
                                normal: '#ff9800',
                                shadowColor: '#fcebad'
                            }
                        }
                    }
                }
            ],
            // cofInfo: [
                // {
                //     title: '低谷照明/储存电量',
                //     content: '420kW/天',
                //     text: ''
                // },
                // {
                //     title: '灯具总功率',
                //     content: '120MW',
                //     text: ''
                // },
                // {
                //     title: '峰时储能放电量',
                //     content: '420kW/天',
                //     text: ''
                // },
                // {
                //     title: '市电照明/充电功率',
                //     content: '420MW/年',
                //     text: ''
                // },
                // {
                //     title: '电池组温度: C',
                //     content: '420kW/天',
                //     text: ''
                // },
                // {
                //     title: '储能照明功率',
                //     content: '420',
                //     text: ''
                // },
                // {
                //     title: '蓄电池总放电量',
                //     content: '420kW/月',
                //     text: ''
                // },
                // {
                //     title: '电池温度',
                //     content: '27度',
                //     text: ''
                // },
            // ]
        }
    },
    components: {
        CenterChart
    },
    mounted() {
        this.rate[0].tips = this.a_bliPer
    }
}
</script>

<style lang="scss" scoped>
$box-height: 410px;
$box-width: 420px;

#centerRight1 {
    padding: 16px;
    padding-top: 20px;
    height: $box-height;
    width: $box-width;
    border-radius: 5px;

    .item {
        padding: 10px;
        margin-bottom: 10px;
        width: 48%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: #0d1326;
    }

    .right {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #0d1326;
    }

}
</style>
