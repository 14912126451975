<template>
  <div>
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="220px"
      width="260px"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    lang: {
        default: 'zh'
    },
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    lang: {
      handler (newData) {
        // console.log(this.cdata.seriesData);
        if(newData == 'zh') {
          this.cdata.seriesData.forEach(item => {
            item['tt'] = item.name
            item.name = item.en
            item.en = item.tt
          })
          this.options.series[0].name = '数量';
        }

        if(newData == 'en') {
          this.cdata.seriesData.forEach(item => {
            item['tt'] = item.name
            item.name = item.en
            item.en = item.tt
          })
          this.options.series[0].name = 'number';
        }

      }
    },
    cdata: {
      handler (newData) {
        this.options = {
          color: [
            "#37a2da",
            "#32c5e9",
            "#9fe6b8",
            "#ffdb5c",
            "#ff9f7f",
            "#fb7293",
            "#e7bcf3",
            "#8378ea"
          ],
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
            "textStyle": {
              "fontSize": 22
            }
          },
          toolbox: {
            show: true
          },
          calculable: true,
          // legend: {
          //   orient: "horizontal",
          //   icon: "circle",
          //   bottom: 0,
          //   x: "center",
          //   data: newData.xData,
          //   textStyle: {
          //     color: "#fff"
          //   }
          // },
          series: [
            {
              name: "数量(number)",
              type: "pie",
              radius: [10, 50],
              roseType: "area",
              center: ["50%", "50%"],
              data: newData.seriesData,
              label: {
                normal: {
                  show: true,
                  textStyle: {
                    "fontSize": 22
                  }
                }
              }
            }
          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
</style>