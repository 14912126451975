<template>
    <div id="centerLeft1">
        <div class="bg-color-black">
            <div class="d-flex pt-2 pl-2">
                <span>
                    <icon name="chart-pie" class="text-icon"></icon>
                </span>
                <div class="d-flex">
                    <span v-if="lang == 'zh'" class="fs-xl text mx-2" style="font-size: 26px;">电量状态</span>
                    <span v-if="lang == 'en'" class="fs-xl text mx-2" style="font-size: 26px;">Energy</span>
                    <dv-decoration-1 class="dv-dec-1" />
                </div>
            </div>
            <div class="d-flex jc-center" style="margin-top: 30px;">
                <div style="display: flex;flex-direction: column;align-items: center;">
                    <div :style="{ 'background-color': lightningNum > 90 ? lightningType == 1 ? '#68ca22' : lightningType == 2 ? '#fff21a' : lightningType == 3 ? '#3340df' : '#68ca22' : '#2b3248' }"
                        style="width: 40px;height: 20px;background-color: #2b3248;border-top-left-radius: 10px;border-top-right-radius: 10px;margin-bottom: -3px;">
                    </div>
                    <div class="dianBg">
                        <div>
                            <!-- <div class="lightningText">{{ lightningNum }}%</div> -->
                            <div class="lightningText">{{ batEcele_monthFun() }}</div>
                            <div class="lightningText" style="top: 60%;">{{ unit + '/h' }}</div>
                            
                        </div>
                        <div class="xianshi"
                            :style="{ 'height': lightningNum > 90 ? '100%' : lightningNum + '%', 'background-color': lightningNum > 20 ? '#68ca22' : 'red' }">
                        </div>
                    </div>
                    <span v-if="lang == 'zh'" style="margin-top: 20px;
                                font-size: 27px;
                                font-family: PingFang SC;
                                font-weight: 500;
                                color: #D1D1D1;">{{ lightningType == 1 ? '充电状态' : lightningType == 2 ? '放电状态' :
                                    lightningType == 3 ? '市电状态' : '电量统计' }}</span>

                    <span v-if="lang == 'en'" style="margin-top: 20px;
                                font-size: 27px;
                                font-family: PingFang SC;
                                font-weight: 500;
                                color: #D1D1D1;">{{ lightningType == 1 ? 'Charging status' : lightningType == 2 ? 'Discharging status' :
                                    lightningType == 3 ? 'AC power status' : 'Battery' }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        lang: {
            default: 'zh'
        },
        lightningNum: {
            default: '80',
        },
        lightningType: {   // 1 充电状态  2 放电状态  3 市电状态    其他 剩余电池电量 
            default: '4',
        },
        batEcele_month: {
            default: '4',
        }

    },
    data() {
        return {
            unit: 'kw'
        }
    },
    methods: {
        batEcele_monthFun() {
            if(this.batEcele_month.includes('mw')) {
                this.unit = 'mw'
            }
            const match = this.batEcele_month.match(/(\d+(\.\d+))/);
            return match ? match[1] : 0;
            // this.batEcele_month = parseInt(this.batEcele_month.match(/\d+/));
            
        },
    }
};
</script>
  
<style lang="scss" scoped>
#centerLeft1 {
    $box-width: 320px;
    $box-height: 410px;
    padding: 16px;
    height: $box-height;
    min-width: $box-width;
    border-radius: 5px;

    .bg-color-black {
        height: $box-height - 30px;
        border-radius: 10px;
    }

    .text {
        color: #c3cbde;
    }

    .dv-dec-1 {
        position: relative;
        width: 100px;
        height: 20px;
        top: -3px;
    }

    .chart-box {
        margin-top: 16px;
        width: 170px;
        height: 170px;

        .active-ring-name {
            padding-top: 10px;
        }
    }

    .dianBg {
        position: relative;
        width: 140px;
        height: 240px;
        border-radius: 10px;
        background-color: #2b3248;
        display: flex;
        align-items: flex-end;
        overflow: hidden;

        // background-image: url(../assets/dianliangBg.png);
        // background-size: 100% auto;
        // background-repeat: no-repeat;
        .lightning {
            position: absolute;
            width: 40px;
            height: 73px;
            left: 50%;
            top: 35%;
            transform: translateX(-50%);
        }

        .lightningText {
            position: absolute;
            left: 50%;
            top: 45%;
            transform: translateX(-50%);
            font-size: 34px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
        }

        .xianshi {
            width: 100%;
            background-color: #68ca22;
        }
    }
}
</style>