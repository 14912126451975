<template>
  <div>
    <Chart :cdata="cdata" :Region="Region"/>
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  props: {
    cdata: [],
    Region:{}
  },
  data() {
    return {
      // cdata: [
      //   { name: '建德市', value: [119.279089, 29.472284, 424] },
      //   { name: '上城区', value: [120.171465, 30.250236, 2452] },
      //   { name: '拱墅区', value: [120.150053, 30.314697, 154] },
      //   { name: '西湖区', value: [120.147376, 30.272934, 4345] },
      //   { name: '临安区', value: [119.715101, 30.231153, 312] },
      //   { name: '桐庐县', value: [119.685045, 29.797437, 652] },
      //   { name: '淳安县', value: [119.044276, 29.604177, 531] },
      //   { name: '萧山区', value: [120.27069, 30.162932, 5524] },
      // ]
    }
  },
  components: {
    Chart,
  },
  mounted() {
  },
 
  methods: {
  }
}
</script>

<style lang="scss" scoped></style>