<template>
  <div id="bottomLeft">
    <div class="bg-color-black" :class="photovoltaicType == 2 ? 'bg-photo' : ''">
          <div class="d-flex pt-2 pl-2" style="justify-content: space-between;">
            <!-- <span>
          <icon name="chart-bar" class="text-icon"></icon>
        </span> -->
            <div class="d-flex" style="display: flex;flex-direction: column;align-items: center;">
              <span v-if="lang == 'zh'" class="fs-xl text mx-2" style="font-size: 16px;">数据统计图</span>
              <span v-if="lang == 'en'" class="fs-xl text mx-2" style="font-size: 16px;">Data statistics chart</span>
              <span class="fs-xl text" style="font-size: 0.02rem;color: #c3cbde;font-size: 16px;">单位kW-h</span>
            </div>

            <div class="d-flex" style="display: flex;flex-direction: column;align-items: center;">
              <!-- <span class="fs-xl text mx-2" style="font-size: 16px;">单位万元</span> -->
            </div>

      </div>
      <div>
        <BottomLeftChart :lang="lang" :cdata="cdata" :photovoltaicType="photovoltaicType"/>
      </div>
    </div>
  </div>
</template>

<script>
import BottomLeftChart from '@/components/echart/bottom/bottomLeftChart'
export default {
  props: {
    lang: {
        default: 'zh'
    },
    photovoltaicType: {
      default: 1
    },
    cdata: {},
  },
  watch: {
    photovoltaicType: {
      handler (newData) {
        console.log(newData);
      }
    }
  },
  components: {
    BottomLeftChart
  }
}
</script>

<style lang="scss" scoped>
$box-height: 520px;
$box-width: 100%;

#bottomLeft {
  padding: 20px 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .bg-color-black {
    height: $box-height - 35px;
    border-radius: 10px;
  }

  .bg-photo {
    background: url("../assets/Frame 12@2x.png") no-repeat;
    background-size: cover;
    background-position: center 0px;
  }

  .text {
    color: #c3cbde;
  }

  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;

    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>
