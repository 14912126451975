<template>
  <div style="position: relative;">
    <div style="position: absolute;display: flex;align-items: center;top: -40px;left: 50%;transform: translateX(-50%);width: 53%;">
    
      <div style="display: flex;align-items: center;margin-right: 25px;">
        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
            <div style="width: 18px;height: 18px;background-color: #69ca23;" @click="histogram"></div>

            <div style="display: flex;flex-direction: row;align-items: center;margin-top: 5px;" @click="lineChart">
              <div style="width: 5px;height: 3px;background-color: #00ff00;"></div>
              <div style="width: 15px;height: 15px;border-radius: 50%;border: 1px solid #00ff00;background-color: #fff;"></div>
              <div style="width: 5px;height: 3px;background-color: #00ff00;"></div>
            </div>
        </div>

        <p v-if="lang == 'zh'" style="margin-left: 10px;font-size: 22px;">峰时储能放电量</p>
        <p v-if="lang == 'en'" style="margin-left: 10px;font-size: 22px;">Peak hour energy storage</p>
      </div>

      <div style="display: flex;align-items: center;margin-right: 25px;">
        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
            <div style="width: 18px;height: 18px;background-color: #f96206;" @click="histogram"></div>

            <div style="display: flex;flex-direction: row;align-items: center;margin-top: 5px;" @click="lineChart">
              <div style="width: 5px;height: 3px;background-color: #f96206;"></div>
              <div style="width: 15px;height: 15px;border-radius: 50%;border: 1px solid #f96206;background-color: #fff;"></div>
              <div style="width: 5px;height: 3px;background-color: #f96206;"></div>
            </div>
        </div>

        <p v-if="lang == 'zh'" style="margin-left: 10px;font-size: 22px;">低谷照明/储存电量</p>
        <p v-if="lang == 'en'" style="margin-left: 10px;font-size: 22px;">Low valley lighting/stored electricity</p>
      </div>

      <div style="display: flex;align-items: center;" v-if="photovoltaicType == 2">
        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
            <div style="width: 18px;height: 18px;background-color: #76c3ed;" @click="histogram"></div>

            <div style="display: flex;flex-direction: row;align-items: center;margin-top: 5px;" @click="lineChart">
              <div style="width: 5px;height: 3px;background-color: #76c3ed;"></div>
              <div style="width: 15px;height: 15px;border-radius: 50%;border: 1px solid #76c3ed;background-color: #fff;"></div>
              <div style="width: 5px;height: 3px;background-color: #76c3ed;"></div>
            </div>
        </div>

        <p v-if="lang == 'zh'" style="margin-left: 10px;font-size: 22px;">光伏发电量</p>
        <p v-if="lang == 'en'" style="margin-left: 10px;font-size: 22px;">photovoltaic</p>
      </div>
    
    </div>
    <!-- 年度开工率 -->
      <Echart
      ref="bottomLeftChart"
      :options="options"
      id="bottomLeftChart"
      height="480px"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    lang: {
        default: 'zh'
    },
    photovoltaicType: {
      default: 1
    },
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    lang: {
      handler (newData) {
        console.log(newData);

        if(newData == 'zh') {
          this.options.series[0].name = '峰时储能放电量';
          this.options.series[1].name = '低谷照明/储存电量';
          this.options.xAxis.data = this.cdata.category
        }

        if(newData == 'en') {
          this.options.series[0].name = 'Peak hour energy storage';
          this.options.series[1].name = 'Low valley lighting/stored electricity';
          this.options.xAxis.data = this.cdata.categoryEn
        }

        if(this.photovoltaicType == 2) {
          if(newData == 'zh') {
            this.options.series[2].name = '光伏发电量';
          }
          if(newData == 'en') {
            this.options.series[2].name = 'photovoltaic';
          }
        }

        this.$refs.bottomLeftChart

      }
    },

    photovoltaicType: {
      handler (newData) {
        console.log('photovoltaicType', newData);
        if(newData == 1) {
          this.options.series = this.options.series.slice(0, -1)
          console.log(this.options.series.length);
        }else {
          let ob = {
              name: "光伏发电量",
              type: this.options.series[0].type,
              smooth: true,
              showAllSymbol: true,
              symbol: "emptyCircle",
              symbolSize: 8,
              yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: "#76c3ed"
                }
              },
              data: this.cdata.photovoltaic
            }
          this.options.series.push(ob)
          
        }
      }
    },

    cdata: {
      handler (newData) {
        this.options = {
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(255,255,255,0.1)",
            "textStyle": {
              "fontSize": 22
            },
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                backgroundColor: "#7B7DDC"
              }
            }
          },
          // legend: {
          //   data: ["蓄电池总放电量", "低谷充电存储总电量"],
          //   textStyle: {
          //     color: "#B4B4B4",
          //     fontSize: 20
          //   },
          //   top: "0%"
          // },
          grid: {
            x: "8%",
            width: "88%",
            y: "4%"
          },
          xAxis: {
            data: newData.category,
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },
            axisTick: {
              show: false
            }
          },
          yAxis: [
            {
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4"
                }
              },

              axisLabel: {
                formatter: "{value} "
              }
            },
            {
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4"
                }
              },
              axisLabel: {
                formatter: "{value} kw"
              }
            }
          ],
          series: [
            // {
            //   name: "节省费用",
            //   type: "line",
            //   smooth: true,
            //   showAllSymbol: true,
            //   symbol: "emptyCircle",
            //   symbolSize: 8,
            //   yAxisIndex: 1,
            //   itemStyle: {
            //     normal: {
            //       color: "#F02FC2"
            //     }
            //   },
            //   data: newData.rateData
            // },
            {
              name: "峰时储能放电量",
              type: "bar",
              smooth: true,
              showAllSymbol: true,
              symbol: "emptyCircle",
              symbolSize: 8,
              yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: "#00ff00"
                }
              },
              data: newData.barData
            },
            {
              name: "低谷照明/储存电量",
              type: "bar",
              smooth: true,
              showAllSymbol: true,
              symbol: "emptyCircle",
              symbolSize: 8,
              yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: "#f96206"
                }
              },
              data: newData.lineData
            },
            
            // {
            //   name: "蓄电池总放电量",
            //   type: "bar",
            //   barWidth: 10,
            //   itemStyle: {
            //     normal: {
            //       barBorderRadius: 5,
            //       color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //         { offset: 0, color: "#956FD4" },
            //         { offset: 1, color: "#3EACE5" }
            //       ])
            //     }
            //   },
            //   data: newData.barData
            // },
            // {
            //   name: "低谷充电存储总电量",
            //   type: "bar",
            //   barGap: "-100%",
            //   barWidth: 10,
            //   itemStyle: {
            //     normal: {
            //       barBorderRadius: 5,
            //       color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //         { offset: 0, color: "rgba(156,107,211,0.8)" },
            //         { offset: 0.2, color: "rgba(156,107,211,0.5)" },
            //         { offset: 1, color: "rgba(156,107,211,0.2)" }
            //       ])
            //     }
            //   },
            //   z: -12,
            //   data: newData.lineData
            // }
          ]
        }
      },
      immediate: true,
      deep: true
    },
  },

  methods: {
    lineChart() {
      this.options.series[0].type = 'line'; // 修改第一个 series 的 type 为 'line'
      this.options.series[1].type = 'line'; // 修改第一个 series 的 type 为 'line'
      this.options.series[2].type = 'line'; // 修改第一个 series 的 type 为 'line'
    },

    histogram() {
      this.options.series[0].type = 'bar'; // 修改第一个 series 的 type 为 'bar'
      this.options.series[1].type = 'bar'; // 修改第一个 series 的 type 为 'bar'
      this.options.series[2].type = 'bar'; // 修改第一个 series 的 type 为 'bar'
    },

  }

}
</script>