<template>
  <div id="center" style="display: flex;flex-direction: row;">
    <div class="left">
      <div class="leftItem" :style="{ 'background': item.bgcolor, }" v-for="(item, index) in titleItem" :key="index">
        <!-- <img :src="item.icon" alt=""> -->
        <img v-if="item.id == 'dianliang'" style="width: 3rem;height: 2rem;margin-right: 20px;"
          src="../assets/dianliang.png" alt="">
        <img v-if="item.id == 'jine'" style="width: 2rem;height: 2rem;margin-right: 20px;" src="../assets/jine.png"
          alt="">
        <img v-if="item.id == 'dianbiao'" style="width: 2rem;height: 2rem;margin-right: 20px;"
          src="../assets/dianbiao.png" alt="">
        <img v-if="item.id == 'feiyong'" style="width: 2rem;height: 2rem;margin-right: 20px;"
          src="../assets/feiyong.png" alt="">

        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
          <span class="text" style="line-height: 26px;text-align: center;font-size: 18px;">{{ item.title[lang] }}</span>
          <span class="text" style="margin-top: 10px;font-size: 19px;">{{ item.number }}</span>
        </div>
      </div>
      <div class="leftItem" :style="{ 'background': '#247adc', }" v-if="photovoltaicType == 2">
        <!-- <img :src="item.icon" alt=""> -->
        <img style="width: 2.5rem;height: 2rem;margin-right: 20px;" src="../assets/Frame@2x(2).png" alt="">

        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
          <span class="text" style="line-height: 26px;text-align: center;font-size: 18px;">{{ photovoltaic[0].title[lang]
            }}</span>
          <span class="text" style="margin-top: 10px;font-size: 19px;">{{ photovoltaic[0].number }}</span>
        </div>
      </div>
    </div>

    <div class="right">
      <div
        style="height: 100%;background-color: rgba(19, 25, 47, 0.6);display: flex;flex-direction: column;border-radius: 10px;">
        <div class="rightItem">
          <img style="width: 58px;height: 58px;" src="../assets/Frame@2x.png" alt="">

          <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
            <span class="text" style="line-height: 26px;text-align: center;font-size: 18px;">{{ photovoltaic[1].title[lang]
              }}</span>
            <span class="text" style="margin-top: 5px;font-size: 19px;">{{ reduction() }}</span>
          </div>
        </div>
        <div class="rightItem">
          <img style="width: 58px;height: 58px;" src="../assets/Frame@2x(1).png" alt="">

          <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
            <span class="text" style="line-height: 26px;text-align: center;font-size: 18px;">{{ photovoltaic[2].title[lang]
              }}</span>
            <span class="text" style="margin-top: 5px;font-size: 19px;">{{ planting() }}棵</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CenterChart from '@/components/echart/center/centerChartRate'

export default {
  props: {
    lang: {
      default: 'zh'
    },
    dianliang: {
      default: '1600'
    },
    dianbiao: {
      default: '1600'
    },
    photovoltaicYery: {
      default: '0Kw'
    },
    photovoltaicType: {
      default: 1
    },
  },
  data() {
    return {
      titleItem: [
        {
          id: 'dianliang',
          title: {
            'zh': '低谷照明 & 储存电量(年)',
            'en': 'Low valley lighting (annual)'
          },
          number: this.dianliang,
          bgcolor: '#d9523f',
          icon: '../assets/dianliang.png'
        },
        // {
        //   id: 'jine',
        //   title: '已节省费用',
        //   number: '16000',
        //   bgcolor: '#13d0b2',
        //   icon: '../assets/jine.png'
        // },
        {
          id: 'dianbiao',
          title: {
            'zh': '峰时储能放电量(年)',
            'en': 'Energy storage discharge (annual)'
          },
          number: this.dianbiao,
          bgcolor: '#69ca23',
          icon: '../assets/dianbiao.png'
        }
      ],
      rightItem: [
        {
          title: '在线设备数量',
          number: '16000',
          bgcolor: '#5b6cba'
        },
        {
          title: '离线设备数量',
          number: '8000',
          bgcolor: '#54A2D8'
        },
        {
          title: '故障警报数量',
          number: '10',
          bgcolor: '#74A561'
        },
      ],

      photovoltaic: [
        {
          id: 'photovoltaic',
          title: {
            'zh': '光伏充电量(年）',
            'en': 'Photovoltaic (year)'
          },
          number: this.photovoltaicYery,
          icon: '../assets/feiyong.png'
        },
        {
          id: 'reduction',
          title: {
            'zh': '二氧化碳减排',
            'en': 'carbon dioxide emission reduction'
          },
          number: '1600',
          icon: '../assets/feiyong.png'
        },
        {
          id: 'planting',
          title: {
            'zh': '等效植树',
            'en': 'Equivalent tree planting'
          },
          number: '1600',
          icon: '../assets/feiyong.png'
        }
      ],




    }
  },
  components: {
    // CenterChart
  },
  methods: {

    // 二氧化碳
    reduction() {
      let num = this.photovoltaicYery.slice(0, -2);
      console.log(num * 0.404);
      return num * 0.404
    },
    // 等效植树
    planting() {
      let num = this.photovoltaicYery.slice(0, -2) * 0.404 / 5023
      console.log(num);
      return num.toFixed(2)
    },  



  }
}
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;

  .text {
    color: #fff;
    font-size: 22px;
  }

  .left {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0px 20px 20px;
    width: 60%;
    height: 26rem;

    .leftItem {
      padding: 10px 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      // padding: 20px;
      margin-right: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
    }
  }

  .right {
    flex: 1;
    padding: 20px 20px 20px 0px;


    .rightItem {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      height: 50%;
      // padding: 20px;
      margin-right: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
    }
  }


}
</style>
