<template>
    <div class="content">
      <div ref="charts" style="width: 480px; height: 480px"></div>
  </div>
</template>


<script>
import * as echarts from "echarts";
// import { mapData } from '@/api/map';

// import mapData from "@/common/map/guangzhou.json";
export default {
  props: {
    cdata: [],
    Region:{}
  },
  data() {
    return {
      mapData: {}
    }
  },
  created() {
    this.init()
   
  },
  methods: {
    async init() {
      // const res = await mapData({url: 'http://geo.datav.aliyun.com/areas_v3/bound/' + this.Region + '_full.json'});
      // console.log(res.data);
      this.mapData = JSON.parse(this.Region)
      this.$nextTick(() => {
        this.initCharts();
      })
    },



    initCharts() {
      // console.log('cdata', this.cdata)
      const charts = echarts.init(this.$refs["charts"]);
      const option = {
        // 背景颜色
        backgroundColor: "rgba(0,0,0, 0)",
        // 提示浮窗样式
        tooltip: {
          show: true,
          trigger: "item",
          alwaysShowContent: false,
          backgroundColor: "#0C121C",
          borderColor: "rgba(0, 0, 0, 0.16);",
          hideDelay: 100,
          triggerOn: "mousemove",
          enterable: true,
          textStyle: {
            color: "#DADADA",
            fontSize: "12",
            width: 20,
            height: 30,
            overflow: "break",
          },
          showDelay: 100,
          formatter: function (params) {
            console.log(params)
            // return `<div style="border-radius: 5px; font-weight: 600;color:#fff;padding:5px 10px;">${params.data.num}</div>`;
            return `地区：${params.name}</br>数值：${params.value[2]}`;
          },
        },
        // 地图配置
        geo: {
          map: "map",

          label: {
            // 通常状态下的样式
            normal: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
            // 鼠标放上去的样式
            emphasis: {
              textStyle: {
                color: "#fff",
              },

            },
          },
          // 地图区域的样式设置
          itemStyle: {
            normal: {
              borderColor: "rgba(147, 235, 248, 1)",
              borderWidth: 1,
              areaColor: {
                type: "radial",
                x: 0.5,
                y: 0.5,
                r: 0.8,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(147, 235, 248, 0)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(147, 235, 248, .2)", // 100% 处的颜色
                  },
                ],
                globalCoord: false, // 缺省为 false
              },
              shadowColor: "rgba(128, 217, 248, 1)",
              shadowOffsetX: -2,
              shadowOffsetY: 2,
              shadowBlur: 10,
            },
            // 鼠标放上去高亮的样式
            emphasis: {
              areaColor: "#389BB7",
              borderWidth: 0,
            },

          },
        },

        series: [{
          type: 'scatter',
          coordinateSystem: 'geo',
          symbol: 'circle',
          symbolSize: 10,
          color: "#37a2da",
          data: this.cdata
        }],

      };
      // 地图注册，第一个参数的名字必须和option.geo.map一致
      echarts.registerMap("map", this.mapData)

      charts.setOption(option);
    },
  },
};
</script>

