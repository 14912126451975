<template>
    <div id="centerLeft1">
        <div class="bg-color-black">
            <div class="com" v-if="lang == 'zh'">
                <img src="../../assets/Group 11@2x.png" style="width: 110px;height: 110px;" alt="">

                <div style="margin-top: 16px;">
                    <span style="font-weight: 400;font-size: 22px;color: #44FFF9;">辐照</span>
                </div>

                <span class="text01">{{ irradiation }}</span>

                <span class="text02">PV 电压(V)</span>
                <span class="text03">{{ sun_vin }}</span>


                <span class="text02">充电电流(A)</span>
                <span>{{ sun_cur }}</span>

                <span class="text02">充电功率(w)</span>
                <span>{{ chargingPower() }}</span>

            </div>

            <div class="com" v-else>
                <img src="../../assets/Group 11@2x.png" style="width: 110px;height: 110px;" alt="">

                <div style="margin-top: 16px;">
                    <span style="font-weight: 400;font-size: 22px;color: #44FFF9;">Irradiation</span>
                </div>

                <span class="text01">3000W/m^2</span>

                <span class="text02">VP voltage (V)</span>
                <span class="text02">Charging current(A)</span>
                <span class="text02">Charging power(w)</span>
            </div>





        </div>
    </div>
</template>

<script>
export default {
    props: {
        lang: {
            default: 'zh'
        },
        irradiation: {
            default: '0'
        },
        sun_cur: {
            default: '0'
        },
        sun_vin: {
            default: '0'
        },

    },
    data() {
        return {
            
        }
    },
    methods: {
        chargingPower() {
            return (this.sun_vin * this.sun_cur).toFixed(3)
        },
    }
};
</script>

<style lang="scss" scoped>
#centerLeft1 {
    $box-width: 320px;
    $box-height: 410px;
    padding: 16px;
    height: $box-height;
    min-width: $box-width;
    border-radius: 5px;

    .bg-color-black {
        height: $box-height - 30px;
        border-radius: 10px;
    }

    .com {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .text01 {
        font-weight: 600;
        font-size: 22px;
        color: #FFFFFF;
        margin-top: 8px;
        margin-bottom: 16px;
    }

    .text02 {
        font-weight: 400;
        font-size: 18px;
        color: #2FC5C6;
        margin-bottom: 6px;
    }

    .text03{
        font-size: 18px;
        color: #fff;
    }

}
</style>